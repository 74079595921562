var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.link ? _c('a', {
    staticClass: "org__link mt-6 mt-lg-12",
    attrs: {
      "href": _vm.link,
      "target": "_blank"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tit))]), _c('v-icon', {
    staticClass: "font-size-18 mt-8 mt-md-0 ml-4"
  }, [_vm._v("mdi-chevron-right")])], 1) : _c('div', {
    staticClass: "org__link org__link--disabled mt-6 mt-lg-12"
  }, [_c('span', [_vm._v(_vm._s(_vm.tit))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }